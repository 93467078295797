import React from 'react';

import styles from './header.module.scss';

interface IProps {
  pageName: string;
}

const Header = ({ pageName }: IProps) => (
  <>
    <h1 className={styles.header}>{pageName}</h1>
    <div className={styles.divider} />
  </>
);

export default Header;

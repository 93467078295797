import React from 'react';

import { IProduct } from '@features/shop/interfaces/shop';
import Product from '../product/product';
import { IDirectusShopItem } from '../../../../types/directus/shop';
import useGetProductPlansData from '../../hooks/useGetProductPlansData';
import { isUsingGBPCurrency } from '@src/services/global';

interface IProps {
  product: IDirectusShopItem;
  addToBasket: (product: IProduct, dataPlans: any) => void;
}

const BundleProduct = ({ product, addToBasket }: IProps) => {
  let { dataPlans, price, offerPrice } = useGetProductPlansData(product.identifier);

  if (!dataPlans || !isUsingGBPCurrency()) return null;

  return (
    <Product
      product={product}
      addToBasket={addToBasket}
      data={dataPlans}
      price={price}
      offerPrice={offerPrice}
      loading={false}
    />
  );
};

export default BundleProduct;

import React from 'react';

import { ShopBanner } from './banner';
import { IShopCategory } from '@features/shop/interfaces/shop';
import { useGetBanner } from '@features/shop/hooks/useGetBanner';

interface IProps {
  category: IShopCategory;
}

const ShowBanner = ({ category }: IProps) => {
  const banner = useGetBanner(category.title);

  if (!banner) {
    return null;
  }

  return <ShopBanner {...banner} />;
};

export default ShowBanner;

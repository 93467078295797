import React from 'react';
import { Link } from 'gatsby';

import styles from './breadcrumbs.module.scss';

interface IRoute {
  url: string;
  label: string;
}

interface IProps {
  routes?: IRoute[];
}

const Breadcrumbs = ({ routes }: IProps) => {
  if (!routes || routes.length === 1) return null;

  return (
    <div className={styles.breadcrumbs}>
      {routes?.map(route => (
        <Link to={route.url} className={styles.breadcrumb}>
          <span className={styles.labelText}>{route.label}</span>
          <img className={styles.arrow} src="/images/right-chevron.png" alt="right arrow" />
        </Link>
      ))}
    </div>
  );
};

export default Breadcrumbs;

import React from 'react';

import { IShopCategory } from '@features/shop/interfaces/shop';
import ProductsWithBanner from './products-with-banner';
import ProductsGrid from './product-grid';

import styles from './products.module.scss';

interface IProps {
  categories: IShopCategory[];
}

const Products = ({ categories }: IProps) => {
  return (
    <div className={styles.products}>
      {categories
        .sort((a, b) => {
          return (a.priority === null ? 10 : a.priority) - (b.priority === null ? 10 : b.priority);
        })
        .map((category, index) => {
          if (index === 0) {
            return <ProductsWithBanner key={`${category.id}${index}`} category={category} />;
          }
          return <ProductsGrid key={`${category.id}${index}`} category={category} />;
        })}
    </div>
  );
};
export default Products;

import React from 'react';

import { IProduct } from '@features/shop/interfaces/shop';
import Product from '../product/product';
import useGetEssentialProductPlan from '@features/shop/hooks/useGetEssentialProductPlan';
import useGetProductPlansData from '../../hooks/useGetProductPlansData';

interface IProps {
  product: IProduct;
  addToBasket: (product: IProduct, dataPlans: any) => void;
}

const EssentialProduct = ({ product, addToBasket }: IProps) => {
  const { price, plans, loadingPlans, loadingProducts } = useGetEssentialProductPlan();
  const { price: essentialPrice, loading } = useGetProductPlansData(product.identifier);

  return (
    <Product
      personalized
      product={product}
      addToBasket={addToBasket}
      data={plans}
      price={price}
      basePersonalizedPrice={essentialPrice}
      loading={loadingPlans || loadingProducts || loading}
    />
  );
};

export default EssentialProduct;

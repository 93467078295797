import React from 'react';

import Header from '@features/shop/components/header/header';
import Breadcrumbs from '@features/shop/components/breadcrumbs/breadcrumbs';
import DesktopFilters from '@features/shop/components/desktop-filters/desktop-filters';
import MobileFilters from '@features/shop/components/mobile-filters/mobile-filters';
import Products from '@features/shop/components/products/products';
import ShopFooter from '@features/shop/components/shop-footer/shop-footer';
import { IFilterPage } from './interfaces/shop';

import styles from './index.module.scss';

interface IProps {
  data: IFilterPage;
}

const Shop = ({ data }: IProps) => {
  return (
    <div className={styles.shopWrap}>
      <div className={styles.filtersDesktop}>
        <DesktopFilters />
      </div>
      <div className={styles.shop}>
        <Breadcrumbs routes={data.path} />
        <Header pageName={data.filter_title} />
        <p>{data.filter_description}</p>
        <div className={styles.filtersMobile}>
          <MobileFilters />
        </div>

        <Products categories={data.product_categories} />
        <ShopFooter header={data.footer_keyword_header} text={data.footer_keywords} />
      </div>
    </div>
  );
};

export default Shop;

import React from 'react';

import { IShopCategory } from '@features/shop/interfaces/shop';
import ProductsGrid from './product-grid';
import ProductHeader from './product-header';
import ShowBanner from '../banner/show-banner';

import styles from './product-with-banner.module.scss';

interface IProps {
  category: IShopCategory;
}

const ProductsWithBanner = ({ category }: IProps) => {
  return (
    <div>
      <ProductHeader title={category.title} className={styles.hideMe} />
      <div className={styles.withBanner}>
        <div className={styles.bannerWrap}>
          <ShowBanner category={category} />
        </div>
        <ProductsGrid withBanner category={category} />
      </div>
    </div>
  );
};

export default ProductsWithBanner;

import React from 'react';
import { Link } from 'gatsby';

import Button from '@components/button/button';
import { IProduct } from '@features/shop/interfaces/shop';
import { useShopText } from '@features/shop/hooks/useShopText';

import styles from './product.module.scss';
import ProductBadge from './product-badge';
import ProductImage from './product-image';
import { formatPrice } from '@services/format';

interface IProps {
  product: IProduct;
  data: any;
  price?: string;
  basePersonalizedPrice?: string;
  loading: boolean;
  offerPrice?: string;
  fromPrice?: string;
  personalized?: boolean;
  addToBasket: (product: IProduct, data: any) => void;
}

interface IRoute {
  link: string;
  children: React.ReactNode;
}

const ProductLink = ({ link, children }: IRoute) => {
  if (link)
    return (
      <Link to={`/${link}`} className={styles.link}>
        {children}
      </Link>
    );

  return <div className={styles.link}>{children}</div>;
};

const Product = ({
  product,
  data,
  price,
  offerPrice,
  fromPrice,
  loading,
  personalized,
  basePersonalizedPrice,
  addToBasket,
}: IProps) => {
  const shopText = useShopText();

  const priceFrom = () => {
    if (fromPrice) {
      return <span className={styles.from}>{shopText.price_from}</span>;
    }
    return '';
  };

  const renderPrice = () => {
    if (loading)
      return (
        <span className={styles.from}>
          <span className={styles.loader} />
        </span>
      );
    if (personalized && !price && !basePersonalizedPrice) return null;
    if (!price && !basePersonalizedPrice)
      return <span className={styles.outOfStock}>{shopText.back_in_stock_soon}</span>;

    if (fromPrice && fromPrice !== price) {
      return (
        <span>
          {priceFrom()}
          <span className={styles.offerPrice}>{price}</span> {fromPrice}
        </span>
      );
    }

    if (offerPrice) {
      return (
        <span>
          {priceFrom()}
          <span className={styles.offerPrice}>{price}</span> {offerPrice}
        </span>
      );
    }
    return (
      <span>
        {priceFrom()}
        {price || basePersonalizedPrice}
      </span>
    );
  };

  const renderButton = () => {
    if (personalized && !price && !loading) {
      return (
        <Link className={styles.buttonPrimary} to="/consultation">
          Take Health Quiz
        </Link>
      );
    }
    if (!price) return null;
    if (personalized) {
      return (
        <Button
          disabled={loading}
          onClick={() => addToBasket(product, { product_plans: data })}
          className={styles.addToBasketButton}
        >
          {shopText.add_to_basket}
        </Button>
      );
    }
    return (
      <Button
        disabled={loading}
        onClick={() => addToBasket(product, data)}
        className={styles.addToBasketButton}
      >
        {shopText.add_to_basket}
      </Button>
    );
  };

  return (
    <div
      className={styles.product}
      data-testid={`product-${product.product?.name || product.name}`}
    >
      <ProductBadge product={product} />
      <ProductLink link={product.route}>
        <ProductImage
          filenameDisk={product.filename_disk}
          backgroundColor={product.background_color}
        />
      </ProductLink>
      <div className={styles.body}>
        <ProductLink link={product.route}>
          <h3>{product.product?.name || product.name}</h3>
        </ProductLink>
        <p className={styles.description}>{product.description}</p>
        <p className={styles.price}>{renderPrice()}</p>
        <div className={styles.footer}>
          {product.out_of_stock ? (
            <span className={styles.outOfStock}>{shopText.back_in_stock_soon}</span>
          ) : (
            renderButton()
          )}
        </div>
      </div>
    </div>
  );
};

export default Product;

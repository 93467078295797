import React from 'react';
import { graphql } from 'gatsby';

// components
import Layout from '@components/layout';
import SEO from '@components/seo';
import Shop from '@features/shop';
import { IFilterPage } from '@features/shop/interfaces/shop';

export const data = graphql`
  query($path: String!) {
    directusShopFilterTemplate(url: { eq: $path }) {
      page_title
      page_description
      filter_title
      filter_description
      path {
        label
        url
      }
      url
      footer_keyword_header
      footer_keywords
      product_categories {
        title
        priority
        product_items {
          name
          description
          identifier
          filename_disk
          route
          best_seller
          selling_fast
          low_stock
          out_of_stock
          digital_product
          background_color
          product {
            name
            identifier
          }
        }
      }
    }
  }
`;

interface IProps {
  data: {
    directusShopFilterTemplate: IFilterPage;
  };
}

const ShopPage = ({
  data: {
    directusShopFilterTemplate: {
      page_title,
      page_description,
      filter_title,
      filter_description,
      footer_keyword_header,
      footer_keywords,
      path,
      url,
      product_categories,
    },
  },
}: IProps) => {
  return (
    <Layout>
      <SEO title={page_title} description={page_description} />
      <Shop
        data={{
          page_title,
          page_description,
          filter_title,
          filter_description,
          footer_keyword_header,
          footer_keywords,
          path,
          url,
          product_categories,
        }}
      />
    </Layout>
  );
};

export default ShopPage;

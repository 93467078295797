import React from 'react';

import { IProduct } from '@features/shop/interfaces/shop';
import Product from '../product/product';
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';

interface IProps {
  product: IProduct;
  addToBasket: (product: IProduct, dataPlans: any) => void;
}

const RegularProduct = ({ product, addToBasket }: IProps) => {
  const { dataPlans, loading, price, offerPrice, fromPrice } = useGetProductPlansData(
    product.identifier
  );

  return (
    <Product
      product={product}
      addToBasket={addToBasket}
      data={dataPlans}
      price={price}
      offerPrice={offerPrice}
      fromPrice={fromPrice}
      loading={loading}
    />
  );
};

export default RegularProduct;

import React from 'react';
import { Link } from 'gatsby';

import FilterGroup from '../filter-group/filter-group';
import FILTER_GROUPS_DATA from '../../constants/filter-data';
import { useShopText } from '@features/shop/hooks/useShopText';
import styles from './desktop-filters.module.scss';

const DesktopFilters = () => {
  const shopText = useShopText();

  return (
    <div className={styles.filters}>
      <p className={styles.text}>{shopText.filter_by}</p>
      <div className={styles.divider} />
      <FilterGroup isDesktopView filterGroup={FILTER_GROUPS_DATA.categories} />
      <div className={styles.divider} />
      <FilterGroup isDesktopView filterGroup={FILTER_GROUPS_DATA.byType} />
      <div className={styles.divider} />
      <FilterGroup isDesktopView filterGroup={FILTER_GROUPS_DATA.benefits} />
      <div className={styles.divider} />
      <Link to={FILTER_GROUPS_DATA.shop.filterUrlPath} className={styles.filter}>
        {shopText.shop_all}
      </Link>
    </div>
  );
};

export default DesktopFilters;

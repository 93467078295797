import React from 'react';

import Product from '../product/product';
//interfaces
import { IProduct } from '@features/shop/interfaces/shop';
import useGetPersonalizedProductPrice from '@features/shop/hooks/useGetPersonalizedProductPrice';
import useGetProductPlansData from '../../hooks/useGetProductPlansData';

interface IProps {
  product: IProduct;
  addToBasket: (product: IProduct, dataPlans: any) => void;
}

const PersonalizedProduct = ({ product, addToBasket }: IProps) => {
  const {
    personalisedProductPrice,
    loadingPlans,
    loadingProducts,
    plans,
  } = useGetPersonalizedProductPrice();
  const { price, loading } = useGetProductPlansData('personalised');

  return (
    <Product
      personalized
      product={product}
      addToBasket={addToBasket}
      data={plans}
      price={personalisedProductPrice}
      basePersonalizedPrice={price}
      loading={loadingPlans || loadingProducts || loading}
    />
  );
};

export default PersonalizedProduct;

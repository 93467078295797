import { trackEvent } from '@services/tracking/tracking';
import { Link } from 'gatsby';
import React from 'react';

import styles from './banner.module.scss';

interface IProps {
  type: string;
  link: string;
}

export const ShopBanner: React.FC<IProps> = ({ type, link }) => {
  const handleClick = () => trackEvent('Shop Banner Click', { type });

  return (
    <Link to={link} style={{ width: '100%' }} onClick={handleClick}>
      <span className={`${styles.banner} ${styles[type]}`} />
    </Link>
  );
};

import React from 'react';

import styles from './button.module.scss';

interface IProps {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
}

const Button = ({ children, onClick, className = '', disabled }: IProps) => (
  <button className={`${styles.button} ${className}`} disabled={disabled} onClick={onClick}>
    {children}
  </button>
);

export default Button;

import React, { useContext } from 'react';

import { IProduct, IShopCategory } from '@features/shop/interfaces/shop';
import ProductHeader from './product-header';
import { GlobalContext } from '@store/global-state';
import RegularProduct from './regular-product';
import BundleProduct from './bundle-product';
import EssentialProduct from './essential-product';
import PersonalizedProduct from './personalized-product';
import { IDirectusShopItem } from '../../../../types/directus/shop';
import {
  BUNDLE_ITEMS_SKUS,
  ESSENTIAL_PRODUCT_SKU,
  PERSONALIZED_PRODUCT_SKU,
} from '@features/shop/constants/products';
import styles from './product-grid.module.scss';

interface IProps {
  category: IShopCategory;
  withBanner?: boolean;
}

const ProductsGrid = ({ category, withBanner }: IProps) => {
  const { setShowShopModal, setCurrentProductAndPlanData } = useContext(GlobalContext);

  const addToBasketHandler = (product: IProduct, plan: any) => {
    setCurrentProductAndPlanData({ product, plan });
    setShowShopModal(true);
  };

  const renderProducts = (list: IDirectusShopItem[]) =>
    list.map(one => {
      if (one.identifier === PERSONALIZED_PRODUCT_SKU) {
        return (
          <PersonalizedProduct
            key={one.identifier}
            product={one}
            addToBasket={addToBasketHandler}
          />
        );
      }
      if (one.identifier === ESSENTIAL_PRODUCT_SKU) {
        return (
          <EssentialProduct key={one.identifier} product={one} addToBasket={addToBasketHandler} />
        );
      }
      //Once Magento supports bundle prices this can be removed
      if (BUNDLE_ITEMS_SKUS.includes(one.identifier)) {
        return (
          <BundleProduct key={one.identifier} product={one} addToBasket={addToBasketHandler} />
        );
      }
      return <RegularProduct key={one.identifier} product={one} addToBasket={addToBasketHandler} />;
    });

  // First ghostDiv make a empty place for a banner
  return (
    <div className={styles.productsLine}>
      <ProductHeader title={category.title} hideableHeader={withBanner} />
      <div className={`${styles.productsGrid} ${withBanner ? styles.bannerGrid : ''}`}>
        {withBanner ? <div className={styles.ghostDiv} /> : null}
        {renderProducts(category.product_items)}
      </div>
    </div>
  );
};

export default ProductsGrid;

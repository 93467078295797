import React from 'react';

import styles from './shop-footer.module.scss';

interface IProps {
  header?: string;
  text?: string;
}

const ShopFooter = ({ header, text }: IProps) => {
  if (!header && !text) return null;
  return (
    <div className={styles.footer}>
      <p className={styles.header}>{header}</p>
      <p>{text}</p>
    </div>
  );
};

export default ShopFooter;

import React from 'react';

import styles from './product-grid.module.scss';

interface IProductHeader {
  title: string;
  hideableHeader?: boolean;
  className?: string;
}

const ProductHeader = ({ hideableHeader, title, className = '' }: IProductHeader) => (
  <h3 className={`${styles.header} ${hideableHeader ? styles.showMe : ''} ${className}`}>{title}</h3>
);

export default ProductHeader;

import { BANNERS } from '../constants/banners';

const getBannerByType = (type: string) => {
  return BANNERS.find(banner => banner.type === type);
};

export const useGetBanner = (category: string) => {
  const defaultBanner = BANNERS.find(banner => banner.isDefault);

  if (!defaultBanner) {
    return null;
  }

  if (!defaultBanner.excludedShopCategories) {
    return defaultBanner;
  }

  const exceptionCategory = defaultBanner.excludedShopCategories.find(
    excludedCategory => excludedCategory.category === category.toLocaleLowerCase()
  );
  return exceptionCategory ? getBannerByType(exceptionCategory.bannerTypeToUse) : defaultBanner;
};

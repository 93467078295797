import React, { FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import { GlobalContext } from '@store/global-state';
import { SIDEBAR_VIEWS } from '@components/sidebar/constants';
import {
  FILTER_GROUP_BENEFITS_COLLECTION,
  FILTER_GROUP_CATEGORY_COLLECTION,
  FILTER_GROUP_COLLECTION,
  MOBILE_FILTER_GROUPS,
} from '@features/shop/constants/filter-data';
import { useShopText } from '@features/shop/hooks/useShopText';

import styles from './mobile-filters.module.scss';

const MobileFilters = () => {
  const { pathname } = useLocation();
  const shopText = useShopText();
  const [selectedFilter, setSelectedFilter] = useState('');
  const { setSidebar, setFilterName } = useContext(GlobalContext);

  useEffect(() => {
    const filter = FILTER_GROUP_COLLECTION.filter(one => one.filterUrlPath === pathname);

    if (filter && filter.length > 0) {
      setSelectedFilter(filter[0].label);
    }
  }, [pathname]);

  const sidebarOpen = (type: string) => {
    setSidebar(SIDEBAR_VIEWS.filters);
    setFilterName(type);
  };

  const closeFilter = (event: FormEvent<EventTarget>) => {
    event.stopPropagation();
    navigate('/shop');
  };

  const checkIfSelectedIsInCategoryGroup = (name: string) =>
    FILTER_GROUP_CATEGORY_COLLECTION.some(e => e.label === name);
  const checkIfSelectedIsInBenefitGroup = (name: string) =>
    FILTER_GROUP_BENEFITS_COLLECTION.some(e => e.label === name);

  const isBenefit = useMemo(() => checkIfSelectedIsInBenefitGroup(selectedFilter), [selectedFilter]);
  const isCategory = useMemo(() => checkIfSelectedIsInCategoryGroup(selectedFilter), [selectedFilter]);

  const upercaseFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const renderFilterText = (text: string) => {
    if (!selectedFilter || selectedFilter === text) {
      return upercaseFirstLetter(text);
    }

    let currentText = '';

    if (text === MOBILE_FILTER_GROUPS.categories && isCategory) {
      currentText = selectedFilter;
    }
    if (text === MOBILE_FILTER_GROUPS.benefits && isBenefit) {
      currentText = selectedFilter;
    }
    if (!currentText) return upercaseFirstLetter(text);

    return (
      <span className={styles.activeFilter}>
        {upercaseFirstLetter(currentText)}
        <img src="/images/icon_close.svg" onClick={closeFilter} alt="" />
      </span>
    );
  };

  return (
    <div className={styles.filters}>
      <p className={styles.text}>{shopText.filter_by}</p>
      <div className={styles.filterWrap}>
        <div
          className={`${styles.filter} ${isCategory ? styles.active : ''}`}
          onClick={() => sidebarOpen(MOBILE_FILTER_GROUPS.categories)}
        >
          {renderFilterText(MOBILE_FILTER_GROUPS.categories)}
        </div>
        <div
          className={`${styles.filter} ${isBenefit ? styles.active : ''}`}
          onClick={() => sidebarOpen(MOBILE_FILTER_GROUPS.benefits)}
        >
          {renderFilterText(MOBILE_FILTER_GROUPS.benefits)}
        </div>
      </div>
    </div>
  );
};

export default MobileFilters;
